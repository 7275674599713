*{
    color:#fff;

}
html, body{
    width:100%;
    height:100%;
    margin:0;
    padding:0;
    overflow-x:hidden;
    overflow-y: auto;
    background: rgb(61,64,115);
    background: radial-gradient(circle, rgba(61,64,115,1) 0%, rgba(61,79,115,1) 35%, rgba(50,67,89,1) 100%);
}


.list-parent{
    height: 100vh;


    display: grid;
    grid-template-columns: 1fr 1fr 1fr;


}
.list-child{
    height: auto;
    border-radius: 50px;
    border: 1px solid #5ABFA3;
    display: grid;
    grid-template-rows: repeat(5, 150px [row-start]);
    margin: 10px 10px;
    justify-content: center;


}

.list-link{
    background-color: #324359;
    list-style-type: none;
    border: 1px solid #3D4073;
margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 50px;
    box-shadow: inset -28px -28px 55px #344362,
    inset 28px 28px 55px #465b84;

}
.list-link-link{

    background-color: gray;
    border-radius: 25%;

}

.logo-image{
    max-height: 8vh;
    max-width: 5vw;
    min-height: 4vh;
    min-width: 2vw;
    display: flex;
    justify-content: flex-start;
    margin: 10px 10px;

}

.container{
    display: grid;
    grid-template-columns: [first logo-start] 150px [ logo-end content-start] 450px [lastline];

}

.button-container{

    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 10vh;
    margin-top: 1vh;
    text-decoration: none;
}

.title{

    text-decoration: none;

}

.link-stylez{
    text-decoration: none;
    color: white;
}

.App{
    background: rgb(61,64,115);
    background: radial-gradient(circle, rgba(61,64,115,1) 0%, rgba(61,79,115,1) 35%, rgba(26,30,38,1) 100%);

}
.photo-wall-container{

    margin: 1vw 1vw 1vw 1vw

}

.main-content{
    border-radius: 50px;
    display: grid;
    grid-template-rows: 8vh 4vh;
    padding-left: 1vw;
    border: 1px solid #5ABFA3;


}
.landing-title{
    align-items: center;
    display: flex;
    justify-content: flex-start;
    border-radius: 50px;

}
.landing-title-header{
    font-size: 2vw;
}

.landing-content-main{
    font-size: 0.75vw;
    border-radius: 50px;

}